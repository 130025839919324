import React from "react";
import './Status.scss';

import {Radio, RadioGroup} from "@blueprintjs/core";

class Status extends React.Component {
    static classNameFor(status) {
        switch (status) {
            case 0: return "bp3-intent-danger";
            case 0.5: return "bp3-intent-warning";
            case 1: return "bp3-intent-success";
            default: return undefined;
        }
    }

    get radioItems() {
        return Object.values(Status.statuses)
            .map(({weight}, i) =>
                <Radio className={Status.classNameFor(weight)} key={i} value={weight} />);
    }

    render() {
        return (
            <RadioGroup
                onChange={this.props.handleChange}
                selectedValue={this.props.status}
                inline={true}
                className="status"
            >
                {this.radioItems}
            </RadioGroup>
        );
    }
}

Status.statuses = {
    NOT_STARTED: {
        weight: 0,
    },
    STARTED: {
        weight: 0.5,
    },
    COMPLETED: {
        weight: 1,
    },
};

export default Status;
