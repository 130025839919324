import data from "./ipos.json";

const Ipo = {ipos: {}};

// This is only used to display slider label, due to Blueprint slider not supporting custom steps
Ipo.marketCapRanges = {
    "0": {label: "$50M"},
    // "50": {label: "$50M", min: 50, max: 499},
    "500": {label: "$500M", min: 500, max: 999},
    "1000": {label: "$1B", min: 1000, max: 2999},
    "3000": {label: "$3B", min: 3000, max: 4999},
    "5000": {label: "$5B", min: 5000},
    "5500": {label: ">$5B", min: 5000},
};

function mapIpoDatumToIpo(ipoDatum) {
    return {
        ...ipoDatum,
        year: new Date(ipoDatum.tradeDate).getFullYear(),
    };
}

function deserializedIpoData() {
    return data.forEach(function (datum) {
        Ipo.ipos[datum.ticker] = mapIpoDatumToIpo(datum);
    });
}

deserializedIpoData();

export default Ipo;
