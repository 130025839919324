// Data converted using http://www.convertcsv.com/csv-to-json.htm
import data from "./scorecard.json";
import Ipo from "./Ipo.js";

// =================================================================================================
// Reference: https://github.com/thekelvinliu/country-code-emoji/blob/master/src/index.js

// decimal offset between ASCII capitals and regional indicator symbols
const OFFSET = 127397;

// module exposes a single function
export function emojiFrom(country_code) {
    // only allow string input
    if (typeof country_code !== 'string')
        throw new TypeError('argument must be a string');
    // ensure country code is all caps
    const cc = country_code.toUpperCase();
    // return the emoji flag corresponding to country_code or null
    return (/^[A-Z]{2}$/.test(cc))
        ? String.fromCodePoint(...[...cc].map(c => c.charCodeAt() + OFFSET))
        : null;
}

// =================================================================================================

const Scorecard = {investors: undefined};

Scorecard.percentiles = {
    HIGH: {
        min_score: 0.37,
        className: "score-high",
    },
    MED: {
        min_score: 0.13,
        className: "score-med",
    },
    LOW: {
        min_score: 0,
        className: "score-low",
    },
};

const INVESTOR_NAME_MAPPING = {
    "fmr-llc": {
        name: "FMR LLC (Fidelity Management & Research)",
        logo: "",
    },
    "teachers-insurance-&-annuity-association-of-america": {
        name: "TIAA (Teachers Insurance & Annuity Association of America)",
        logo: "",
    },
    "vanguard-group-inc/the": {
        name: "Vanguard Group Inc",
        logo: "",
    },
    "united-services-automobile-association": {
        name: "USAA (United Services Automobile Association)",
        logo: "",
    },
};

const CITY_MAPPING = {
    "New York City/Southern CT/Northern NJ": "NY/CT/NJ",
    "San Francisco/San Jose": "San Francisco Bay Area",
    "Los Angeles/Pasadena": "LA/Pasadena",
};

function isInvestorNonIpoBuyer(investor) {
    return investor.type === "Venture Capital" || typeof investor.type2 !== "undefined";
}

function deserializedScorecardData() {
    let name, city, country, percentile, isNonIpoBuyer, ipos;
    return data
        .filter(function (investor) {
            return investor.lt_score;
        })
        .map(function (investor) {
            name = INVESTOR_NAME_MAPPING[investor.id] ?
                INVESTOR_NAME_MAPPING[investor.id].name : investor.name;
            city = investor.city === "Unclassified" ? "" :
                CITY_MAPPING[investor.city] ? CITY_MAPPING[investor.city] :
                    investor.city;
            country = investor.country ? emojiFrom(investor.country) : null;
            percentile =
                investor.lt_score >= Scorecard.percentiles.HIGH.min_score ? Scorecard.percentiles.HIGH :
                    investor.lt_score >= Scorecard.percentiles.MED.min_score ? Scorecard.percentiles.MED :
                        Scorecard.percentiles.LOW;
            isNonIpoBuyer = isInvestorNonIpoBuyer(investor);
            ipos = investor.ipos.split(",").map(ticker => Ipo.ipos[ticker]);
            return {
                ...investor,
                name,
                country,
                city,
                percentile,
                isNonIpoBuyer,
                ipos,
            };
        });
}

Scorecard.investors = deserializedScorecardData();

export default Scorecard;
