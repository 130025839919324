import React from "react";

import {
    Button,
    Dialog,
    Intent,
    Spinner,
    Icon,
    Checkbox,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import "./Export.scss";

import {statusToaster} from "../toaster";
import download from "downloadjs";

class Export extends React.Component {
    static serializedAssessmentInputFrom(data) {
        const _data = {...data};
        // Series A Ready assessment doesn't have stages
        if (_data.stage) {
            _data.stage = _data.stage.id;
        }
        return JSON.stringify(_data);
    }

    static get successToast() {
        return {
            message: "Progress exported.",
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            timeout: 2000,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            presentActivityIndicator: false,
        };
        this.presentDialog = this.presentDialog.bind(this);
        this.dismissDialog = this.dismissDialog.bind(this);
    }

    presentDialog() {
        if (window.gtag) {
            window.gtag("event", "open_save_progress_dialog", {
                "event_category": "app",
            });
        }
        this.setState({isDialogOpen: true});
    }

    dismissDialog() {
        this.setState({isDialogOpen: false});
    }

    reset() {
        this.setState({
            email: undefined,
            feedback: undefined,
            presentActivityIndicator: false,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (window.gtag) {
            window.gtag("event", "save_progress", {
                "event_category": "app",
            });
        }

        const serializedAssessmentInput = Export.serializedAssessmentInputFrom(this.props.input);

        // Dismiss dialog first before turning page into an image to be inserted into PDF
        this.dismissDialog();
        download(
            new Blob(
                [serializedAssessmentInput],
                {type: 'application/json'},
            ),
            `ipo-ready-assessment-response-data-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.data`,
        );

        const payload = {
            // Channel, name and icon are defined in Slack's webhook setup page
            "text": serializedAssessmentInput,
            "attachments": [
                {
                    "fields": [
                        {
                            "title": "Name",
                            "value": this.props.input.user.name,
                            "short": true,
                        },
                        {
                            "title": "Email",
                            "value": this.props.input.user.email,
                            "short": true,
                        },
                        {
                            "title": "Title",
                            "value": this.props.input.user.job_title,
                            "short": true,
                        },
                        {
                            "title": "Company",
                            "value": this.props.input.user.company_name,
                            "short": true,
                        },
                        {
                            "title": "Location",
                            "value": this.props.input.user.company_location,
                            "short": true,
                        },
                        {
                            "title": "Financing",
                            "value": this.props.input.user.financing_round,
                        },
                        {
                            "title": "Question",
                            "value": this.props.input.user.question,
                        },
                        {
                            "title": "Newsletter",
                            "value": this.props.input.user.newsletter_signup,
                        },
                    ],
                },
            ],
        };

        this.setState({presentActivityIndicator: true});

        return fetch(this.props.slackWebhookUri, {
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then(response => {
                // Slack responds with "ok" text
                if (response.status === 200) {
                    this.props.prepareAssessmentForExport(() => {
                        statusToaster.show(Export.successToast);
                        this.reset();
                    });
                } else {
                    this.setState({presentActivityIndicator: false});
                   
                }
            })
            .catch(error => {
                this.setState({presentActivityIndicator: false});
                statusToaster.show({
                    message: `Could not send message, error = ${error}`,
                    intent: Intent.DANGER,
                });
            });
    }

    get newsletterInputView() {
        return (
            <div className="bp3-form-group">
                <Checkbox
                    id="export-newsletter-signup"
                    data-field-key="newsletter_signup"
                    defaultChecked={typeof this.props.input.user.newsletter_signup === "undefined" ? true : this.props.input.user.newsletter_signup}
                    label={this.props.newsletterLabel}
                    onChange={this.props.handleChange}
                />
            </div>
        );
    }

    get dialogContent() {
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="bp3-dialog-body">
                    <p>
                        Tell us a bit more about you, then export your progress to a data file.
                        You can continue later by restoring your progress with this file.
                    </p>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="export-name">
                            Name<Icon icon={IconNames.DOT} intent={Intent.PRIMARY} />
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-name"
                                data-field-key="name"
                                className="bp3-input bp3-fill"
                                type="text"
                                dir="auto"
                                defaultValue={this.props.input.user.name}
                                onChange={this.props.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="export-email">
                            Email<Icon icon={IconNames.DOT} intent={Intent.PRIMARY} />
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-email"
                                data-field-key="email"
                                className="bp3-input bp3-fill"
                                type="email"
                                dir="auto"
                                defaultValue={this.props.input.user.email}
                                onChange={this.props.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="export-job-title">
                            Job title<Icon icon={IconNames.DOT} intent={Intent.PRIMARY} />
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-job-title"
                                data-field-key="job_title"
                                className="bp3-input bp3-fill"
                                type="text"
                                dir="auto"
                                defaultValue={this.props.input.user.job_title}
                                onChange={this.props.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="export-company-name">
                            Company name<Icon icon={IconNames.DOT} intent={Intent.PRIMARY} />
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-company-name"
                                data-field-key="company_name"
                                className="bp3-input bp3-fill"
                                type="text"
                                dir="auto"
                                defaultValue={this.props.input.user.company_name}
                                onChange={this.props.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="export-company-location">
                            Company location<Icon icon={IconNames.DOT} intent={Intent.PRIMARY} />
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-company-location"
                                data-field-key="company_location"
                                className="bp3-input bp3-fill"
                                type="text"
                                dir="auto"
                                defaultValue={this.props.input.user.company_location}
                                onChange={this.props.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group">
                        <label className="bp3-label" htmlFor="export-financing-round">
                            Latest financing round
                        </label>
                        <div className="bp3-form-content">
                            <input
                                id="export-financing-round"
                                data-field-key="financing_round"
                                className="bp3-input bp3-fill"
                                type="text"
                                dir="auto"
                                defaultValue={this.props.input.user.financing_round}
                                onChange={this.props.handleChange}
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group">
                        <label className="bp3-label" htmlFor="export-question">
                            {this.props.questionLabel}
                        </label>
                        <div className="bp3-form-content">
                            <textarea
                                id="export-question"
                                data-field-key="question"
                                className="bp3-input bp3-fill"
                                dir="auto"
                                rows={3}
                                defaultValue={this.props.input.user.question}
                                onChange={this.props.handleChange}
                            />
                        </div>
                    </div>
                    {this.props.newsletterLabel && this.newsletterInputView}
                    <p className="disclaimer">
                        <span>Orrick needs the contact information you provide to potentially contact you about our products and services. By providing your contact information, you also make your assessment data available to Orrick. We will not share this information outside the organization. For more information on our privacy practices and commitment to protecting your privacy, check out Orrick’s Privacy Policy </span>
                        <a href="https://www.orrick.com/privacy-policy" target="_blank" rel="noopener">here</a>
                        .
                    </p>
                </div>
                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button
                            onClick={this.dismissDialog}
                        >
                            Cancel
                        </Button>
                        {
                            this.state.presentActivityIndicator ?
                                <Spinner className="bp3-small" /> :
                                <Button
                                    intent={Intent.PRIMARY}
                                    type="submit"
                                >
                                    Send and Download
                                </Button>
                        }
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return [
            <Button
                key="button"
                className="feedback-button"
                onClick={this.presentDialog}
                intent={Intent.PRIMARY}
                small={true}
            >
                Save Progress
            </Button>,
            <Dialog
                key="dialog"
                className="export-dialog"
                isOpen={this.state.isDialogOpen}
                onClose={this.dismissDialog}
                title="Save Progress"
                isCloseButtonShown={false}
            >
                {this.dialogContent}
            </Dialog>,
        ];
    }
}

export default Export;
