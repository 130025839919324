import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Dialog,
    Intent,
    Spinner,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import "./Feedback.scss";

import {statusToaster} from "../toaster";

class Feedback extends React.Component {
    static get successToast() {
        return {
            message: "Feedback sent.",
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            timeout: 2000,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            email: undefined,
            feedback: undefined,
            presentActivityIndicator: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.presentDialog = this.presentDialog.bind(this);
        this.dismissDialog = this.dismissDialog.bind(this);
    }
    presentDialog({mailto, label}) {
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
       /* if (window.gtag) {
            window.gtag("event", "open_feedback_dialog", {
                "event_category": "app",
            });
        }
        this.setState({isDialogOpen: true});*/
    }

    dismissDialog() {
        this.setState({isDialogOpen: false});
    }

    reset() {
        this.setState({
            email: undefined,
            feedback: undefined,
            presentActivityIndicator: false,
        });
    }

    handleChange(event) {
        const key = event.target.id === "feedback-email-input" ? "email" : "feedback";
        this.setState({[key]: event.target.value});
    }

    handleSubmitFeedback(event) {
        event.preventDefault();

        const payload = {
            // Channel, name and icon are defined in Slack's webhook setup page
            "attachments": [
                {
                    "fields": [
                        {
                            "title": "From",
                            "value": this.state.email,
                            "short": true,
                        },
                        {
                            "title": "Viewing",
                            "value": window.location.href,
                            "short": true,
                        },
                        {
                            "title": "Message",
                            "value": this.state.feedback,
                        },
                    ],
                    "actions": [
                        {
                            "type": "button",
                            "text": "Reply",
                            "url": `mailto:${this.state.email}?&cc=support@ltse.com&subject=Your%20IPO%20Ready%20Feedback&body=${this.state.feedback.substring(0, 1900)}`,
                        },
                    ],
                },
            ],
        };

        this.setState({presentActivityIndicator: true});

        return fetch(this.props.slackWebhookUri, {
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then(response => {
                // Slack responds with "ok" text
                if (response.status === 200) {
                    this.dismissDialog();
                    statusToaster.show(Feedback.successToast);
                    this.reset();
                } else {
                    this.setState({presentActivityIndicator: false});
                   
                }
            })
            .catch(error => {
                this.setState({presentActivityIndicator: false});
                statusToaster.show({
                    message: `Could not send message, error = ${error}`,
                    intent: Intent.DANGER,
                });
            });
    }

    get dialogContent() {
        return (
            <form onSubmit={this.handleSubmitFeedback.bind(this)}>
                <div className="bp3-dialog-body">
                    <p>{this.props.intro}</p>
                    <div className="bp3-form-group bp3-inline">
                        <label className="bp3-label" htmlFor="feedback-email-input">Email</label>
                        <div className="bp3-form-content">
                            <input
                                id="feedback-email-input"
                                className="bp3-input bp3-fill"
                                type="email"
                                dir="auto"
                                defaultValue={this.state.email}
                                onChange={this.handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="bp3-form-group">
                        <label className="bp3-label" htmlFor="feedback-content-input">Question and Feedback</label>
                        <div className="bp3-form-content">
                            <textarea
                                id="feedback-content-input"
                                className="bp3-input bp3-fill"
                                dir="auto"
                                rows={10}
                                defaultValue={this.state.feedback}
                                onChange={this.handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button
                            onClick={this.dismissDialog}
                        >
                            Cancel
                        </Button>
                        {
                            this.state.presentActivityIndicator ?
                                <Spinner className="bp3-small" /> :
                                <Button
                                    intent={Intent.PRIMARY}
                                    type="submit"
                                >
                                    Send
                                </Button>
                        }
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return [
       <Button
            label={this.props.buttonLabel}
               className="feedback-button"
                 onClick={(e) => {
                window.location = this.props.mailto;
               e.preventDefault();
            }}>
                {this.props.buttonLabel}
              </Button>,
            <Dialog
                key="dialog"
                className="feedback-dialog"
                isOpen={this.state.isDialogOpen}
                onClose={this.dismissDialog}
                title="Contact Us"
                isCloseButtonShown={false}
            >
                {this.dialogContent}
            </Dialog>,
        ];
    }
}

export default Feedback;
