import React from "react";
import {
    Icon,
    // Intent,
    // Position,
    // Alignment,
    Button,
    ButtonGroup,
    Card,
    NonIdealState,
    Switch,
    Tooltip,
    RangeSlider,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import './LtScorecard.scss';
import Scorecard from "../data/Scorecard";
import Ipo from "../data/Ipo.js";

function ScoreRangeVisualization() {
    return (
        <div className="score-range-visualization">
            <div className="low">
                <span className="small-label">50th Percentile</span>
                <span className="score score-low">{Math.round(Scorecard.percentiles.MED.min_score * 100 - 1)}</span>
            </div>
            <div className="med">
                <span className="small-label">85th Percentile</span>
                <span className="score score-med">{Math.round(Scorecard.percentiles.HIGH.min_score * 100 - 1)}</span>
            </div>
            <div className="high">
                <span className="score score-high">100</span>
            </div>
        </div>
    );
}

function DealCounter(props) {
    const deals = new Array(props.deals).fill(null);
    return (
        <div className="deal-counter">
            <div className="visualization">
                {deals.map((_, i) => <span key={i} />)}
            </div>
            <span>IPOs</span>
        </div>
    );
}

function InvestorType(props) {
    let view;
    if (props.note) {
        view = (
            <Tooltip content={props.note}>
                <div className="investor-type">
                    {props.type}
                    <Icon icon={IconNames.INFO_SIGN} iconSize={12} />
                </div>
            </Tooltip>
        );
    } else {
        view = <div className="investor-type">{props.type}</div>;
    }
    return view;
}

function InvestorCard(props) {
    return (
        <Card className="investor-card">
            {props.rank && <div className="investor-card-rank">{props.rank}</div>}
            <div className="left">
                <div className="investor-card-name">
                    <h2>{props.name}</h2>
                </div>
            </div>
            <div className="right">
                <div className="investor-card-score">
                    <span className="small-label">LT Score</span>
                    <span className={`score ${props.percentile.className}`}>{Math.round(props.lt_score * 100)}</span>
                </div>
                <div className="bottom">
                    <DealCounter deals={props.deals} />
                    {props.aum && <div>${new Intl.NumberFormat().format(props.aum)} M Assets</div>}
                    <InvestorType type={props.type} note={props.note} />
                    <div className="geolocation">
                    <span className="country">
                        {props.country}
                    </span>
                    <span className="city">
                        {props.city}
                    </span>
                    </div>
                </div>
            </div>
        </Card>
    );
}

function InvestorList(props) {
    return (
        <ul className="investor-list unstyled-list">
            {
                props.investors.map(function (investor, i) {
                    return (
                        <li
                            key={investor.id}
                            className="investor-list-item"
                        >
                            <InvestorCard
                                name={investor.name}
                                lt_score={investor.lt_score}
                                deals={investor.deals}
                                aum={investor.aum}
                                country={investor.country}
                                city={investor.city}
                                type={investor.type2 || investor.type}
                                note={investor.note}
                                percentile={investor.percentile}
                                rank={props.presentRank ? (i <= 2 && i + 1) : null}
                            />
                        </li>
                    );
                })
            }
        </ul>
    );
}

class LtScorecard extends React.Component {
    static get lackOfResultsView() {
        return (
            <NonIdealState icon={IconNames.ZOOM_OUT} title="No search results" />
        );
    }

    constructor(props) {
        super(props);

        let nameFilterPredicate;
        if (window.URLSearchParams) {
            const urlSearchParams = new URLSearchParams(props.history.location.search);
            nameFilterPredicate = urlSearchParams.get("q");
        }

        this.state = {
            nameFilterPredicate: nameFilterPredicate || undefined,
            leaderBoardPresented: "top",
            shouldIncludeNonIpoBuyers: false,
            marketCapRange: [0, 5500],
        };
        this.isIpoMarketCapInRange = this.isIpoMarketCapInRange.bind(this);
        this.handleNameFilterPredicateChange = this.handleNameFilterPredicateChange.bind(this);
        this.handleLeaderBoardPresentationChange = this.handleLeaderBoardPresentationChange.bind(this);
        this.handleShouldIncludeNonIpoBuyersChange = this.handleShouldIncludeNonIpoBuyersChange.bind(this);
        this.handleMarketCapRangeChange = this.handleMarketCapRangeChange.bind(this);
    }

    componentDidMount() {
        document.title = LtScorecard.pageTitle;
        document.querySelector('meta[name=description]').content =
            LtScorecard.pageDescription;
    }

    isIpoMarketCapInRange(ipo) {
        if (this.state.marketCapRange[1] === 5500 && ipo.marketCap > 5000) {
            return true;
        } else if (ipo.marketCap >= this.state.marketCapRange[0] && ipo.marketCap <= this.state.marketCapRange[1]) {
            return true;
        }
    }

    get topInvestors() {
        return Scorecard.investors
            .filter(investor =>
                this.state.shouldIncludeNonIpoBuyers ? true : !investor.isNonIpoBuyer &&
                    investor.ipos.find(this.isIpoMarketCapInRange)
            )
            .sort((investorA, investorB) => investorA.lt_score > investorB.lt_score ? -1 : 1)
            .slice(0, 10);
    }

    get bottomInvestors() {
        return Scorecard.investors
            .filter(investor =>
                investor.max_ipo_allocation_percentile > 0.25 &&
                investor.deals >= 3 &&
                (this.state.shouldIncludeNonIpoBuyers ? true : !investor.isNonIpoBuyer) &&
                investor.ipos.find(this.isIpoMarketCapInRange)
            )
            .sort((investorA, investorB) => investorA.lt_score < investorB.lt_score ? -1 : 1)
            .slice(0, 10);
    }

    get filteredInvestors() {
        return Scorecard.investors
            .filter(investor => investor.name
                .toLowerCase()
                .includes(this.state.nameFilterPredicate.toLowerCase())
            )
            .sort((investorA, investorB) => investorA.name < investorB.name ? -1 : 1)
            .slice(0, 10);
    }

    get leaderBoardView() {
        return (
            <div className={`leader-board ${this.state.leaderBoardPresented}`}>
                <div className="top column" key="top">
                    <h3 className="bp3-heading">Highest ranked long-term IPO buyers</h3>
                    <div className="offset-column">
                        <InvestorList investors={this.topInvestors} presentRank />
                    </div>
                </div>
                <div className="bottom column" key="bottom">
                    <h3 className="bp3-heading">Large, short-term IPO buyers</h3>
                    <div className="offset-column">
                        <InvestorList investors={this.bottomInvestors} />
                    </div>
                </div>
            </div>
        );
    }

    get leaderBoardSelectorView() {
        return (
            <div className="leader-board-selector">
                <ButtonGroup fill>
                    <Button
                        className="top"
                        data-leader-board="top"
                        onClick={this.handleLeaderBoardPresentationChange}
                        active={this.state.leaderBoardPresented === "top"}
                    >
                        Long-Term
                    </Button>
                    <Button
                        className="bottom"
                        data-leader-board="bottom"
                        onClick={this.handleLeaderBoardPresentationChange}
                        active={this.state.leaderBoardPresented === "bottom"}
                    >
                        Short-Term
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

    get filterResultsView() {
        return (
            <div className="column">
                {this.filteredInvestors.length ? <InvestorList investors={this.filteredInvestors} /> : LtScorecard.lackOfResultsView}
            </div>
        );
    }

    static get formattedUpdateDate() {
        return LtScorecard.dataUpdatedAt
            .toLocaleDateString("en-US", {year: "numeric", month: "long"});
    }

    static marketCapRangeSliderLabelRenderer(a) {
        if (Ipo.marketCapRanges[a]) {
            return Ipo.marketCapRanges[a].label;
        }
    }

    handleNameFilterPredicateChange(event) {
        this.props.history.push({
            search: `?q=${event.target.value}`,
        });
        this.setState({nameFilterPredicate: event.target.value});
    }

    handleLeaderBoardPresentationChange(event) {
        if (this.state.leaderBoardPresented !== event.currentTarget.dataset.leaderBoard) {
            this.setState({leaderBoardPresented: event.currentTarget.dataset.leaderBoard});
        }
    }

    handleShouldIncludeNonIpoBuyersChange(event) {
        this.setState({shouldIncludeNonIpoBuyers: event.target.checked});
    }

    handleMarketCapRangeChange(marketCapRange) {
        this.setState({marketCapRange});
    }

    render() {
        return (
            <div id="lt-scorecard" className="app-body">
                <section className="intro">
                    <div className="column">
                        <h3 className="bp3-heading">Methodology</h3>
                        <p>
                            The <strong>LT Score</strong> algorithm is based on how long institutional investors maintain their holdings in companies after an IPO. The algorithm is weighted by IPO allocation and by holdings over time.
                        </p>
                        <p>
                            For a perfect score of 100, an investor would consistently have significant IPO allocations (25th percentile) in at least 2 IPOs, and hold 20 out of every 20 quarters after the IPO. To earn a score of zero, an investor sells everything one quarter after the IPO, and has participated in at least 3 IPOs with at least 25th percentile allocation in one of those.
                        </p>
                        <p>
                            All investors whose LT Score is 50th percentile or below are colored red. All investors whose LT Score is 85th percentile or above are colored blue.
                        </p>
                        <ScoreRangeVisualization />
                        <p>
                            Our algorithm is based on the academic research by Elyasiani and Jia (2010), by modifying their MSPD (maintain-stake-points duration) algorithm specifically for IPOs.
                        </p>
                    </div>
                    <div className="column description">
                        <h3 className="bp3-heading">Dataset</h3>
                        <p>
                            Our dataset includes over 100,000 data points across 20 quarters of holdings data. Investors were selected for the analysis who had significant imputed allocation in multiple IPOs. Imputed IPO allocation was determined by holdings at first quarter after IPO. Our data is refreshed quarterly based on SEC filings.
                        </p>
                        <div className="row small-label">
                            <div>Last refresh: {LtScorecard.formattedUpdateDate}</div>
                            <a href="mailto:ray@ltse.com?subject=Long Term Scorecard">Request full dataset</a>
                        </div>
                    </div>
                </section>
                <section className="tools">
                    <div className="bp3-input-group name-search">
                        <Icon icon={IconNames.SEARCH} />
                        <input
                            id="scorecard-investor-name-input"
                            className="bp3-input bp3-fill"
                            type="search"
                            dir="auto"
                            placeholder="Search LT Scorecard by investor name"
                            defaultValue={this.state.nameFilterPredicate}
                            onChange={this.handleNameFilterPredicateChange}
                        />
                    </div>
                    {/* Turns on when user is filtering by name, but not by other predicate */}
                    <Switch
                        className="non-ipo-buyer-switch"
                        checked={this.state.nameFilterPredicate ? true : this.state.shouldIncludeNonIpoBuyers}
                        label="Custodians, index funds and VC funds"
                        onChange={this.handleShouldIncludeNonIpoBuyersChange}
                        large={true}
                        disabled={!!this.state.nameFilterPredicate}
                    />
                    <div className="market-cap-range">
                        <label className="bp3-label">
                            Investor participated in this range of market caps at IPO
                        </label>
                        <RangeSlider
                            className="market-cap-range-slider"
                            min={0}
                            max={5500}
                            stepSize={500}
                            labelStepSize={500}
                            onChange={this.handleMarketCapRangeChange}
                            value={this.state.marketCapRange}
                            labelRenderer={LtScorecard.marketCapRangeSliderLabelRenderer}
                        />
                    </div>
                    {this.state.nameFilterPredicate ? null : this.leaderBoardSelectorView}
                    {/*<Button*/}
                    {/*icon="search"*/}
                    {/*intent={Intent.PRIMARY}*/}
                    {/*minimal*/}
                    {/*onClick={this.temp}*/}
                    {/*disabled*/}
                    {/*>*/}
                    {/*Search (coming soon)*/}
                    {/*</Button>*/}
                </section>
                <section className="data">
                    {this.state.nameFilterPredicate ? this.filterResultsView : this.leaderBoardView}
                </section>
            </div>
        );
    }
}

LtScorecard.appName = "Long Term Scorecard";
LtScorecard.pageTitle = "Long Term Scorecard | IPO Ready";
LtScorecard.pageDescription = "Allocate IPO shares to institutional investors based on their long-term orientation.";

LtScorecard.dataUpdatedAt = new Date(2019, 0, 6);

export default LtScorecard;
