import React, {Component} from 'react';
import './IpoReady.scss';

import {
    Button,
    Collapse,
    FocusStyleManager,
    Icon,
    Intent,
    Position,
    ProgressBar,
    Radio,
    Spinner,
    Switch,
    Tooltip,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import Status from "../Status/Status";
import InlineHelp from "../InlineHelp/InlineHelp";
import Tags from "../Tags/Tags";
import Feedback from "../Feedback/Feedback";
import Export from "../Export/Export";

import {config} from "../config";
import Assessment from "../data/IpoReadyAssessment";
import {statusToaster} from "../toaster";

FocusStyleManager.onlyShowFocusOnTabs();

class IpoReady extends Component {

    static progressBarIntentForScoreAndTarget(score, target) {
        return (target - score) > 1 ?
            Intent.DANGER :
            score >= target ? Intent.SUCCESS : Intent.WARNING;
    }

    static deserializedAssessmentInputFrom(data) {
        data.stage = Assessment.stages[data.stage];
        return data;
    }

    static get importSuccessToast() {
        return {
            message: "Progress imported.",
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            timeout: 2000,
        };
    }

    taskListItemContentFor(task) {
        const debugView = this.props.isDebug ? (
            <div className="debug">
                {(task.tags && task.tags.length) ? <Tags tags={task.tags} /> : null}
                <span>Stage year: {task.stage.year}</span>
                <span>Weight: {task.weight}</span>
            </div>
        ) : null;
        return (
            <div className="task-list-item-content">
                {task.label}
                {task.helpContent && <InlineHelp>
                    <div dangerouslySetInnerHTML={{__html: task.helpContent}} />
                </InlineHelp>}
                {debugView}
            </div>
        );
    }

    scoreFor(sectionTaskLists) {
        const totalScore = sectionTaskLists.reduce((sum, sectionTaskList) =>
            sum + sectionTaskList.reduce((_sum, _task) => _sum + _task.weight, 0),
            0,
        );
        const sectionScore = sectionTaskLists.reduce((sum, sectionTaskList) =>
            sum + sectionTaskList.reduce((sum, task) => {
                let result = sum;
                const status = this.state.assessmentInput.tasks[task.id] ?
                    this.state.assessmentInput.tasks[task.id].status : null;
                if (status) {
                    result += task.weight * status;
                }
                return result;
            }, 0),
            0,
        );
        return (sectionScore / totalScore * 10) * (7 / 10);
    }

    constructor(props) {
        super(props);

        this.state = {
            isAssessmentFormDirty: false,
            willWarnBeforeExit: false,
            assessmentDidLoad: false,
            general: {
                taskFilterPredicate: undefined,
            },
            assessmentInput: {
                stage: Assessment.stages.ASPIRE,
                tasks: {},
                user: {},
            },
            sections: {},
        };
    }

    get filterView() {
        const filters = Object.entries(Tags.tags).map(([tagKey, tag]) => {
            const className =
                `bp3-tag ${tag.type.className} ${tag === this.state.general.taskFilterPredicate ? "" : "bp3-minimal "}`;
            return (
                <button
                    key={tagKey}
                    className={className}
                    onClick={this.handleTagClick.bind(this)}
                    data-custom={tagKey}
                >
                    {tag.content}
                </button>
            );
        });
        const clearButton = (
            <button
                onClick={this.clearFilter.bind(this)}
                className="bp3-button bp3-small"
            >
                Clear
            </button>
        );
        return (
            <div id="filters">
                <div className="input-row">
                    <h5 className="bp3-heading">Filter Tasks</h5>
                    {this.state.general.taskFilterPredicate && clearButton}
                </div>
                <div>{filters}</div>
            </div>
        );
    }

    get egcView() {
        return (
            <div className="input-row">
                <label className="switch-row">
                    <span>Emerging Growth Company (EGC) status</span>
                    <Switch />
                </label>
                <InlineHelp>
                    <div>
                        <h5 className='bp3-heading'>What is it</h5>
                        <p>From Jumpstart Our Business Startups Act</p>
                        <h5 className='bp3-heading'>How to Qualify</h5>
                        <ul>
                            <li>Have not filed S-1 and sold shares publicly</li>
                            <li>Issued more than $1b in non-convertible debt in past
                                3
                                years
                            </li>
                            <li>Not a Large Accelerated Filer (public float >=
                                $700m)
                            </li>
                        </ul>
                    </div>
                </InlineHelp>
            </div>
        );
    }

    get toDoList() {
        const tasks = Object.values(this.tasks)
            .reduce((output, sectionData) =>
                    [
                        ...output,
                        ...sectionData.tasks.filter(task =>
                            !(this.state.assessmentInput.tasks[task.id] &&
                                this.state.assessmentInput.tasks[task.id].status),
                        ),
                    ],
                [],
            )
            .sort((a, b) => {
                if (b.stage.year < a.stage.year) return -1;
                if (b.stage.year > a.stage.year) return 1;
                if (b.weight < a.weight) return -1;
                if (b.weight > a.weight) return 1;
                if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
                if (b.label.toLowerCase() < a.label.toLowerCase()) return 1;
                return 0;
            });
        const topTasks = tasks.slice(0, 3).map(task => {
            return (
                <li key={task.label}>
                    {this.taskListItemContentFor(task)}
                </li>
            );
        });
        // console.log(tasks);
        return (
            <ol className="to-do-list">
                {topTasks}
            </ol>
        );
    }

    get toDoListSectionView() {
        return (
            <div className="subsection">
                <h5 className="bp3-heading section-heading">
                    <span>3 priorities to work on next</span>
                    <InlineHelp>
                        This list shows the 3 highest impact
                        tasks that have not yet been started.
                    </InlineHelp>
                </h5>
                {this.toDoList}
            </div>
        );
    }

    get stageSelectorView() {
        return (
            <div id="stage-selector" className="subsection">
                <h5 className="bp3-heading section-heading">What is your stage?</h5>
                <div className="bp3-select bp3-fill">
                    <select
                        onChange={this.handleStageChange.bind(this)}
                        value={this.state.assessmentInput.stage.id}
                    >
                        {Object.values(Assessment.stages).map(stage =>
                            <option key={stage.id} value={stage.id}>
                                {stage.label}
                            </option>,
                        )}
                    </select>
                    <p className="stage-description">{this.state.assessmentInput.stage.description}</p>
                </div>
            </div>
        );
    }

    get outputView() {
        return (
            <section id="output">
                {this.stageSelectorView}
                <div className="sticky">
                    <div className="subsection">
                        <h5 className="bp3-heading section-heading">
                            <span>Your Score & Recommended Target</span>
                            <InlineHelp>
                                Recommended target for your stage. Being too close to fully ready
                                before
                                initiating the IPO process results in resource waste.
                            </InlineHelp>
                        </h5>
                        {this.renderProgressOutputViewForSectionTaskListsAndStage(
                            Assessment.sections.business,
                            [
                                this.tasks.businessEquityStory.tasks,
                                this.tasks.businessGoing.tasks,
                                this.tasks.businessBeing.tasks,
                            ],
                            this.state.assessmentInput.stage,
                        )}
                        {this.renderProgressOutputViewForSectionTaskListsAndStage(
                            Assessment.sections.team,
                            [
                                this.tasks.teamAdvisors.tasks,
                                this.tasks.teamBoard.tasks,
                                this.tasks.teamInternal.tasks,
                            ],
                            this.state.assessmentInput.stage,
                        )}
                        {this.renderProgressOutputViewForSectionTaskListsAndStage(
                            Assessment.sections.finance,
                            [
                                this.tasks.financeBudget.tasks,
                                this.tasks.financeSystems.tasks,
                                this.tasks.financeOther.tasks,
                                this.tasks.financeFinancialStatements.tasks,
                            ],
                            this.state.assessmentInput.stage,
                        )}
                        {this.renderProgressOutputViewForSectionTaskListsAndStage(
                            Assessment.sections.legal,
                            [
                                this.tasks.legalPolicies.tasks,
                                this.tasks.legalGeneral.tasks,
                                this.tasks.legalSec.tasks,
                            ],
                            this.state.assessmentInput.stage,
                        )}
                        {this.renderProgressOutputViewForSectionTaskListsAndStage(
                            Assessment.sections.other,
                            [
                                this.tasks.otherCompensation.tasks,
                                this.tasks.otherGeneral.tasks,
                                this.tasks.otherExchange.tasks,
                            ],
                            this.state.assessmentInput.stage,
                        )}
                    </div>
                    {this.toDoListSectionView}
                    <div className="subsection actions">
                        <div>
                            <Export
                                input={this.state.assessmentInput}
                                handleChange={this.handleExportFormChange.bind(this)}
                                prepareAssessmentForExport={this.expandAll.bind(this)}
                                isDebug={this.props.isDebug}
                                slackWebhookUri={config.ipoReadySlackWebhookUri}
                            />
                            <Tooltip
                                content="Select a local data file saved from this app"
                                position={Position.TOP}
                                className="tooltip-wrapper"
                                targetClassName="tooltip-target"
                            >
                                <label htmlFor="restore-progress-file-input" className="bp3-button">
                                    Restore Progress
                                </label>
                            </Tooltip>
                            <input
                                id="restore-progress-file-input"
                                type="file"
                                onChange={this.handleProgressDataFileSelection.bind(this)}
                            />
                        </div>
                        <div>
                            <Button onClick={this.print.bind(this)}>
                                Print to PDF
                            </Button>
                            <Feedback
                                mailto="mailto:TCGIPOReadyTool@orrick.com"
                                buttonLabel="Help and Feedback"
                                intro={Assessment.contactFormInstructions}
                                slackWebhookUri={config.seriesAReadySlackWebhookUri}


                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    get assessmentView() {
        return (
            <div className="content">
                <section id="input" className={this.state.isAssessmentFormDirty ? "" : "is-dirty"}>
                    {/*<section id="general" className="section">*/}
                    {/*/!*{this.filterView}*!/*/}
                    {/*/!*{this.egcView}*!/*/}
                    {/*</section>*/}
                    <section id="business" className="section bp3-card bp3-elevation-2">
                        <div className="section-heading-container">
                            <h2 className="bp3-heading section-heading">
                                <Icon
                                    icon={IconNames.OFFICE}
                                    className="section-heading-icon"
                                    iconSize={24}
                                />
                                <a className="same-page-link" href="#business">Business</a>
                            </h2>
                            <p className="subheading">
                                Build a strong, investable business well prepared to operate as
                                a public company, or for other transactions such as a sale or
                                alternative exit.
                            </p>
                        </div>
                        {this.renderSubsectionWith(this.tasks.businessEquityStory)}
                        {this.renderSubsectionWith(this.tasks.businessGoing)}
                        {this.renderSubsectionWith(this.tasks.businessBeing)}
                    </section>
                    <section id="team" className="section bp3-card bp3-elevation-2">
                        <div className="section-heading-container">
                            <h2 className="bp3-heading section-heading">
                                <Icon
                                    icon={IconNames.PEOPLE}
                                    className="section-heading-icon"
                                    iconSize={24}
                                />
                                <a className="same-page-link" href="#team"><span>Team</span></a>
                            </h2>
                            <p className="subheading">
                                Assemble experienced and credible internal and external teams to
                                support the IPO process and beyond as a public company.
                            </p>
                        </div>
                        {this.renderSubsectionWith(this.tasks.teamInternal)}
                        {this.renderSubsectionWith(this.tasks.teamBoard)}
                        {this.renderSubsectionWith(this.tasks.teamAdvisors)}
                    </section>
                    <section id="finance" className="section bp3-card bp3-elevation-2">
                        <div className="section-heading-container">
                            <h2 className="bp3-heading section-heading">
                                <Icon
                                    icon={IconNames.CHART}
                                    className="section-heading-icon"
                                    iconSize={24}
                                />
                                <a className="same-page-link" href="#finance">Finance</a>
                            </h2>
                            <p className="subheading">
                                Accurately present data and confidently forecast with strong
                                controls
                                and infrastructure.
                            </p>
                        </div>
                        {this.renderSubsectionWith(this.tasks.financeFinancialStatements)}
                        {this.renderSubsectionWith(this.tasks.financeOther)}
                        {this.renderSubsectionWith(this.tasks.financeSystems)}
                        {this.renderSubsectionWith(this.tasks.financeBudget)}
                    </section>
                    <section id="legal-compliance" className="section bp3-card bp3-elevation-2">
                        <div className="section-heading-container">
                            <h2 className="bp3-heading section-heading">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className="section-heading-icon"
                                    style={{transform: "scale(1.1)"}}
                                >
                                    <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
                                </svg>
                                <a className="same-page-link" href="#legal-compliance">Legal &
                                    Compliance</a>
                            </h2>
                            <p className="subheading">
                                Work with advisors to perform due diligence and remove
                                roadblocks.
                            </p>
                        </div>
                        {this.renderSubsectionWith(this.tasks.legalSec)}
                        {this.renderSubsectionWith(this.tasks.legalGeneral)}
                        {this.renderSubsectionWith(this.tasks.legalPolicies)}
                    </section>
                    <section id="other" className="section bp3-card bp3-elevation-2">
                        <div className="section-heading-container">
                            <h2 className="bp3-heading section-heading">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className="section-heading-icon"
                                    style={{transform: "scale(1.3)"}}
                                >
                                    <path d="M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M4.22,4.22C5.65,2.79 8.75,3.43 12,5.56C15.25,3.43 18.35,2.79 19.78,4.22C21.21,5.65 20.57,8.75 18.44,12C20.57,15.25 21.21,18.35 19.78,19.78C18.35,21.21 15.25,20.57 12,18.44C8.75,20.57 5.65,21.21 4.22,19.78C2.79,18.35 3.43,15.25 5.56,12C3.43,8.75 2.79,5.65 4.22,4.22M15.54,8.46C16.15,9.08 16.71,9.71 17.23,10.34C18.61,8.21 19.11,6.38 18.36,5.64C17.62,4.89 15.79,5.39 13.66,6.77C14.29,7.29 14.92,7.85 15.54,8.46M8.46,15.54C7.85,14.92 7.29,14.29 6.77,13.66C5.39,15.79 4.89,17.62 5.64,18.36C6.38,19.11 8.21,18.61 10.34,17.23C9.71,16.71 9.08,16.15 8.46,15.54M5.64,5.64C4.89,6.38 5.39,8.21 6.77,10.34C7.29,9.71 7.85,9.08 8.46,8.46C9.08,7.85 9.71,7.29 10.34,6.77C8.21,5.39 6.38,4.89 5.64,5.64M9.88,14.12C10.58,14.82 11.3,15.46 12,16.03C12.7,15.46 13.42,14.82 14.12,14.12C14.82,13.42 15.46,12.7 16.03,12C15.46,11.3 14.82,10.58 14.12,9.88C13.42,9.18 12.7,8.54 12,7.97C11.3,8.54 10.58,9.18 9.88,9.88C9.18,10.58 8.54,11.3 7.97,12C8.54,12.7 9.18,13.42 9.88,14.12M18.36,18.36C19.11,17.62 18.61,15.79 17.23,13.66C16.71,14.29 16.15,14.92 15.54,15.54C14.92,16.15 14.29,16.71 13.66,17.23C15.79,18.61 17.62,19.11 18.36,18.36Z" />
                                </svg>
                                <a className="same-page-link" href="#other">Other</a>
                            </h2>
                        </div>
                        {this.renderSubsectionWith(this.tasks.otherExchange)}
                        {this.renderSubsectionWith(this.tasks.otherCompensation)}
                        {this.renderSubsectionWith(this.tasks.otherGeneral)}
                    </section>
                </section>
                {this.outputView}
            </div>
        );
    }

    handleTagClick(event) {
        this.setState({
            general: {
                ...this.state.general,
                taskFilterPredicate: Tags.tags[event.target.dataset.custom],
            },
        });
    }

    handleStatusUpdateForTaskInSection(task, section) {
        return ({currentTarget}) => {
            const status = parseFloat(currentTarget.value);
            const oldTaskIndex = section.tasks.indexOf(task);
            const newTasks = section.tasks.slice();
            newTasks[oldTaskIndex].status = status;
            if (window.gtag) {
                window.gtag("event", "change_status", {
                    "event_category": "task",
                    "event_label": task.label,
                    "value": task.status,
                });
            }
            this.setState({
                assessmentInput: {
                    ...this.state.assessmentInput,
                    tasks: {
                        ...this.state.assessmentInput.tasks,
                        [task.id]: {status},
                    },
                },
                isAssessmentFormDirty: !this.state.isAssessmentFormDirty ?
                    true : this.state.isAssessmentFormDirty,
            });
        };
    }

    handleStageChange({target}) {
        if (window.gtag) {
            window.gtag("event", "select_stage", {
                "event_category": "assessment",
                "value": target.value,
            });
        }
        this.setState({
            assessmentInput: {
                ...this.state.assessmentInput,
                stage: Assessment.stages[target.value],
            },
        });
    }

    handleProgressDataFileSelection(event) {
        if (window.gtag) {
            window.gtag("event", "restore_progress", {
                "event_category": "app",
            });
        }
        const fileReader = new FileReader();
        fileReader.onload = event => {
            const result = JSON.parse(event.target.result);
            this.setState(
                {
                    assessmentInput: IpoReady.deserializedAssessmentInputFrom(result),
                    isAssessmentFormDirty: true,
                },
                function () {
                    statusToaster.show(IpoReady.importSuccessToast);
                });
        };
        fileReader.readAsText(event.target.files.item(0));
    }

    handleExportFormChange(event) {
        const key = event.target.dataset.fieldKey;
        const valueKey = event.target.type === "checkbox" ? "checked" : "value";
        this.setState({
            assessmentInput: {
                ...this.state.assessmentInput,
                user: {
                    ...this.state.assessmentInput.user,
                    [key]: event.target[valueKey],
                },
            }
        });
    }

    handleExpand(event) {
        const sectionId = event.currentTarget.dataset.sectionId;
        if (window.gtag) {
            window.gtag("event", "expand_section", {
                "event_category": "app",
                "event_label": sectionId,
            });
        }
        this.setState({
            sections: {
                ...this.state.sections,
                [sectionId]: {
                    isOpen: !this.state.sections[sectionId].isOpen,
                },
            },
        });
    }

    expandAll(callback) {
        const sections = {...this.state.sections};
        Object.values(sections).forEach(section => {
            section.isOpen = true;
        });
        this.setState({sections}, callback);
    }

    clearFilter() {
        this.setState({
            general: {
                ...this.state.general,
                taskFilterPredicate: undefined,
            },
        });
    }

    print() {
        if (window.gtag) {
            window.gtag("event", "print", {
                "event_category": "app",
            });
        }
        this.expandAll(function () {
            window.print();
        });
    }

    renderTaskListFor(section) {
        let filteredTasks;
        if (this.state.general.taskFilterPredicate) {
            filteredTasks = section.tasks.filter(
                task => task.tags && task.tags.includes(this.state.general.taskFilterPredicate),
            );
        }
        // TODO: add back all tags
        const taskListItems = (filteredTasks || section.tasks).map((task) => {
            let filteredTags;
            if (task.tags && task.tags.length) {
                filteredTags = task.tags
                    .filter(tag => [Tags.tags.LTSE, Tags.tags.ORRICK].includes(tag));
            }
            const status = this.state.assessmentInput.tasks[task.id] ?
                this.state.assessmentInput.tasks[task.id].status : null;
            return <div key={task.label} className="input-row">
                <Status
                    handleChange={this.handleStatusUpdateForTaskInSection(task, section)}
                    status={status}
                />
                {(filteredTags && filteredTags.length && !this.props.isDebug) ?
                    <Tags tags={filteredTags} /> : null}
                {this.taskListItemContentFor(task)}
            </div>;
        });
        return (
            <div className="task-list">
                {taskListItems}
                {/* Need a fixed height spacer instead of margin / padding because Blueprint
                    Collapse animation doesn't take margin / padding into consideration */}
                <div className="spacer" />
            </div>
        );
    }

    renderSubsectionWith(sectionData) {
        return (
            <div className="subsection">
                <h4 id={sectionData.id} className="bp3-heading section-heading">
                    <Button
                        onClick={this.handleExpand.bind(this)}
                        className="bp3-button bp3-small"
                        icon={
                            this.state.sections[sectionData.id].isOpen ?
                                IconNames.CARET_DOWN : IconNames.CARET_RIGHT
                        }
                        data-section-id={sectionData.id}
                    />
                    <a className="same-page-link" href={`#${sectionData.id}`}>
                        {sectionData.label}
                    </a>
                    {
                        sectionData.description &&
                        <InlineHelp>
                            <div dangerouslySetInnerHTML={{__html: sectionData.description}} />
                        </InlineHelp>
                    }
                </h4>
                <Collapse isOpen={this.state.sections[sectionData.id].isOpen}>
                    {this.renderTaskListFor(sectionData)}
                </Collapse>
            </div>
        );
    }

    renderProgressOutputViewForSectionTaskListsAndStage(section, taskLists, stage) {
        const score = this.scoreFor(taskLists);
        const target = section.recommendedTargets.get(stage);
        return (
            <div className="progress-output">
                <strong>{section.label}</strong>
                <div className="score">
                    <ProgressBar
                        className="bp3-no-animation"
                        intent={IpoReady.progressBarIntentForScoreAndTarget(score, target)}
                        value={score / 7}
                    />
                    <span className="bp3-monospace-text bp3-text-small">{Math.round(score)} / 7</span>
                </div>
                <div className="score">
                    <ProgressBar
                        className="bp3-no-animation"
                        intent={Intent.NONE}
                        value={target / 7}
                    />
                    <span className="bp3-monospace-text bp3-text-small">{target} / 7</span>
                </div>
            </div>
        );
    }

    componentDidMount() {
        Assessment.fetchTasks.then(tasks => {
            this.tasks = tasks;
            const state = {
                assessmentDidLoad: true,
                sections: {},
            };
            Object.keys(tasks).forEach((sectionId, i) => {
                state.sections[sectionId] = {
                    isOpen: this.props.isDebug ? true : (i === 0),
                };
            });
            this.setState(state);
        });
        if (this.props.isDebug) {
            fetch("../ipo-ready-assessment-sample-response.json")
                .then(function (response) {
                    return response.json();
                })
                .then(data => {
                    this.setState({assessmentInput: IpoReady.deserializedAssessmentInputFrom(data)});
                });
        }
    }

    componentDidUpdate() {
        if (this.state.isAssessmentFormDirty &&
            !this.state.willWarnBeforeExit &&
            !this.props.isDebug) {
            window.addEventListener("beforeunload", function (event) {
                event.returnValue =
                    "The assessment is not saved. Use browser's print to PDF feature to save progress.";
            });
            this.setState({willWarnBeforeExit: true});
        }
    }

    render() {
        return (
            <div id="ipo-ready" className="app-body">
                <section className="intro">
                    <p>
                        Take 20 minutes to understand what it means to be ready for an IPO, and if
                        you're ahead or behind schedule.
                        Choose your stage, review and score each item, and see if you're on track.
                    </p>
                    <div id="status-legend" className="status">
                        <Radio className="bp3-intent-danger" checked readOnly inline label="To start" />
                        <Radio className="bp3-intent-warning" checked readOnly inline label="In progress" />
                        <Radio className="bp3-intent-success" checked readOnly inline label="Finished" />
                    </div>
                </section>
                {this.state.assessmentDidLoad ? this.assessmentView : <Spinner size={50} />}
            </div>
        );
    }
}

IpoReady.appName = "IPO Ready";

export default IpoReady;
