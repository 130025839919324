import React from "react";
import {Spinner} from "@blueprintjs/core";
import './ContentExporter.scss';
import Assessment from "../data/SeriesAReadyAssessment";
// import Assessment from "../data/IpoReadyAssessment";

class ContentExporter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assessmentDidLoad: false,
        };
    }

    static taskListFor(section) {
        return section.tasks.map(function (task) {
            return (
                <div key={task.id}>
                    <h3 className="bp3-heading">{task.label}</h3>
                    <dl>
                        <dt>Weight</dt>
                        <dd>{task.weight}</dd>
                        {task.stage && [
                            <dt>Stage</dt>,
                            <dd>{task.stage.label}</dd>,
                        ]}
                    </dl>
                    <p dangerouslySetInnerHTML={{__html: task.helpContent}} />
                </div>
            );
        })
    }

    get taskLists() {
        return Object.values(this.tasks).map(section => {
            return (
                <section className="section" key={section.id}>
                    <h2 className="bp3-heading">{section.label}</h2>
                    <p dangerouslySetInnerHTML={{__html: section.description}} />
                    <div>
                        {ContentExporter.taskListFor(section)}
                    </div>
                </section>
            );
        });
    }

    componentDidMount() {
        Assessment.fetchTasks.then(tasks => {
            this.tasks = tasks;
            this.setState({assessmentDidLoad: true});
        });
    }

    render() {
        return (
            <div className="app-body">
                {this.state.assessmentDidLoad ? this.taskLists : <Spinner size={50} />}
            </div>
        );
    }
}

export default ContentExporter;
