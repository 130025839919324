import React from "react";
import './Tags.scss';
import LTSELogoSrc from "../media/ltse-logo.svg";
import orrickLogoSrc from "../media/orrick-logo.svg";

class Tags extends React.Component {
    static contentViewFrom(content, key) {
        return typeof content === "string" ? (
            <span
                key={key}
                className="bp3-tag bp3-minimal bp3-intent-primary"
            >
                {content}
            </span>
        ) : content;
    }
    render() {
        return (
            <div className="tags">
                {this.props.tags.map((tag, i) => <div key={i}>{Tags.contentViewFrom(tag.content, i)}</div>)}
            </div>
        );
    }
}

Tags.tags = {
    LEGAL_COUNSEL: {content: "Legal Counsel"},
    UNDERWRITER: {content: "Underwriter"},
    AUDITOR: {content: "Auditor"},
    EXCHANGE: {content: "Listing Exchange"},
    SOX: {content: "SOX"},
    EXCHANGE_ACT: {content: "Exchange Act"},
    JOBS_ACT: {content: "JOBS Act"},
    DODD_FRANK_ACT: {content: "Dodd-Frank Act"},
    LTSE: {
        content: <img src={LTSELogoSrc} alt="LTSE logo" className="partner-logo" title="Relevant to the LTSE listing standards" />,
    },
    ORRICK: {
        content: <img src={orrickLogoSrc} alt="Orrick logo" className="partner-logo" title="Additional insight from Orrick" />,
    },
};

export default Tags;
