import React from "react";
import {Icon, Colors, PopoverPosition} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import './InlineHelp.scss';

import {Popover} from "@blueprintjs/core";

class InlineHelp extends React.Component {
    render() {
        return (
            <Popover
                boundary="viewport"
                modifiers={{
                    arrow: { enabled: true },
                    preventOverflow: { enabled: true },
                }}
                placement={this.props.placement || PopoverPosition.RIGHT}
                className="inline-help"
                portalClassName="inline-help-content"
                popoverClassName="bp3-popover-content-sizing"
            >
                <Icon icon={IconNames.INFO_SIGN} iconSize={16} color={Colors.GRAY1} />
                {this.props.children}
            </Popover>
        );
    }
}

export default InlineHelp;
