import Tags from "../Tags/Tags";
import {IconNames} from "@blueprintjs/icons";

const SeriesAReadyAssessment = {
    tasks: undefined,
    pageTitle: "Series A Ready | Raise your first priced round with guided assessment",
    pageDescription: "Quickly assess your company's Series A readiness. Learn what it takes to prepare for your first priced round. Tools by LTSE.",
    headerDescription: "Take 20 minutes to understand what it means to be ready for Series A, and if you're ahead or behind schedule. Review and score each task, and see if you're on track.",
};

SeriesAReadyAssessment.taskImportanceWeights = {
    LESS: 0.3,
    MEDIUM: 0.5,
    MORE: 0.7,
    CRUCIAL: 1,
};

SeriesAReadyAssessment.sections = {
    businessAndTeam: {
        label: "Business & Team",
        icon: IconNames.OFFICE,
        subhead: "Accurately present the company’s narrative, projections, results and the team.",
        taskListIds: [
            "fundraisingMaterials",
            "team",
            "financial",
            "contracts",
        ],
    },
    paperwork: {
        label: "Paperwork",
        icon: IconNames.BOX,
        subhead: "Make sure that your startup has its legal paperwork in order so investors can perform due diligence.",
        taskListIds: [
            "incorporation",
            "corporateMaintenance",
        ],
    },
    capTable: {
        label: "Cap Table",
        icon: IconNames.TH,
        subhead: "Verify who owns the company’s shares, who has been promised equity and who are the current investors.",
        taskListIds: [
            "stockRecords",
            "priorInvestments",
            "equityGrants",
        ],
    },
};

SeriesAReadyAssessment.contactFormInstructions = "What do you like about Series A Ready? What can we do to improve? Need help with your Series A? Let the Orrick team know.";
SeriesAReadyAssessment.questionLabel = "A question about Series A";

function fetchAssessmentData() {
    return fetch("./series-a-ready-assessment.json").then(function (response) {
        return response.json();
    });
}

function deserialized(rawAssessment) {
    const result = {};
    Object.entries(rawAssessment).forEach(function ([key, section]) {
        section.tasks.forEach(function (task) {
            task.weight = SeriesAReadyAssessment.taskImportanceWeights[task.weight];
            if (task.tags) {
                task.tags.forEach(function (tag, i) {
                    task.tags[i] = Tags.tags[tag];
                });
            }
        });
        result[key] = section;
    });
    return result;
}

SeriesAReadyAssessment.fetchTasks = fetchAssessmentData().then(deserialized);

export default SeriesAReadyAssessment;
