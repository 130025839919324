import React from 'react';
import ReactDOM from 'react-dom';
// For IE 11 and Google crawler compatibility
import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'es7-shim';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ContentExporter from "./Exporter/ContentExporter";
import IPO from "./IpoReady/IpoReady";
 import SeriesA from "./SeriesAReady/SeriesAReady";
import LtScorecard from "./LtScorecard/LtScorecard";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import './index.scss';

let isDebug;
if (window.URLSearchParams) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    isDebug = urlSearchParams.get("debug");
}

const isPresentingLTScorecard = window.location.pathname.includes("scorecard");
const headerinfo = process.env.REACT_APP_SELECTION_FLAG === "SeriesA" ? SeriesA : IPO;

const AppRouter = () => (
    <BrowserRouter>
        <div className="app">
            {/* TODO: 404 <Route component={NoMatch} /> */}
            <Header
                isDebug={!!isDebug}
                app={isPresentingLTScorecard ? LtScorecard : headerinfo}
            />
            <Switch>
            <Route exact path = "/" render = { props => process.env.REACT_APP_SELECTION_FLAG === "SeriesA" ? <SeriesA isDebug={!!isDebug} /> : <IPO isDebug={!!isDebug} /> } /> 
            <Route exact path="/ipo" render={props => <IPO isDebug={!!isDebug} />} />
            <Route exact path="/seriesa" render={props => <SeriesA isDebug={!!isDebug} />} />
            <Route exact path="https://happy-mud-04ea99610.4.azurestaticapps.net" render={props => <IPO isDebug={!!isDebug} />} />
            <Route exact path="https://seriesaready.com" render={props => <SeriesA isDebug={!!isDebug} />} />
            
            <Route path="/content-exporter/" component={ContentExporter} />
             <Route path="/long-term-scorecard/" component={LtScorecard} />
            </Switch>
            <Footer
                app={isPresentingLTScorecard ? LtScorecard : headerinfo}
            />
        </div>
    </BrowserRouter>
);

ReactDOM.render(
    <AppRouter />,
    document.getElementById('root'),
);
