import Tags from "../Tags/Tags";

const IpoReadyAssessment = {
    tasks: undefined,
    pageTitle: "IPO Ready | Go public with the guided assessment tool by LTSE",
    pageDescription: "Quickly assess your company's IPO readiness. Learn what it takes to prepare for an IPO. Tools by LTSE.",
    headerDescription: "Take 20 minutes to understand what it means to be ready for an IPO, and if you're ahead or behind schedule. Choose your stage, review and score each item, and see if you're on track.",
};

IpoReadyAssessment.stages = {
    ASPIRE: {
        id: "ASPIRE",
        label: "Early: 3 ~ 4 years from IPO",
        description: "Early in your startup journey and aspiring to have an IPO. May have 1 or 2 rounds of funding, and initial product / market fit.",
        year: 4,
    },
    ASSESS: {
        id: "ASSESS",
        label: "Assess and plan: 2 ~ 3 years from IPO",
        description: "Planning to IPO in the foreseeable future. Starting to build out the FP&A team and assess IPO readiness.",
        year: 2,
    },
    START: {
        id: "START",
        label: "Start: kicking off the 6 ~ 9 months IPO process",
        description: "Kicking off the IPO process with the initial all-hands meeting.",
        year: 1,
    },
};

IpoReadyAssessment.defaultStage = IpoReadyAssessment.stages.ASPIRE;

IpoReadyAssessment.taskImportanceWeights = {
    LESS: 0.3,
    MEDIUM: 0.5,
    MORE: 0.7,
    CRUCIAL: 1,
};

IpoReadyAssessment.sections = {
    business: {
        label: "Business",
        recommendedTargets: new Map([
            [IpoReadyAssessment.stages.ASPIRE, 2],
            [IpoReadyAssessment.stages.ASSESS, 3],
            [IpoReadyAssessment.stages.START, 7],
        ]),
    },
    team: {
        label: "Team",
        recommendedTargets: new Map([
            [IpoReadyAssessment.stages.ASPIRE, 1],
            [IpoReadyAssessment.stages.ASSESS, 2],
            [IpoReadyAssessment.stages.START, 7],
        ]),
    },
    finance: {
        label: "Finance",
        recommendedTargets: new Map([
            [IpoReadyAssessment.stages.ASPIRE, 2],
            [IpoReadyAssessment.stages.ASSESS, 3],
            [IpoReadyAssessment.stages.START, 7],
        ]),
    },
    legal: {
        label: "Legal",
        recommendedTargets: new Map([
            [IpoReadyAssessment.stages.ASPIRE, 1],
            [IpoReadyAssessment.stages.ASSESS, 2],
            [IpoReadyAssessment.stages.START, 7],
        ]),
    },
    other: {
        label: "Other",
        recommendedTargets: new Map([
            [IpoReadyAssessment.stages.ASPIRE, 1],
            [IpoReadyAssessment.stages.ASSESS, 2],
            [IpoReadyAssessment.stages.START, 7],
        ]),
    },
};

IpoReadyAssessment.contactFormInstructions = "What do you like about IPO Ready? What can we do to improve? Need help with your IPO Readiness? Let the Orrick team know.";
IpoReadyAssessment.questionLabel = "A question about IPO";
IpoReadyAssessment.newsletterLabel = "Sign up for the IPO Insights newsletter";

function fetchAssessmentData() {
    return fetch("./ipo-ready-assessment.json").then(function (response) {
        return response.json();
    });
}

function deserialized(rawAssessment) {
    const result = {};
    Object.entries(rawAssessment).forEach(function ([key, section]) {
        section.tasks.forEach(function (task) {
            task.weight = IpoReadyAssessment.taskImportanceWeights[task.weight];
            task.stage = IpoReadyAssessment.stages[task.stage];
            if (task.tags) {
                task.tags.forEach(function (tag, i) {
                    task.tags[i] = Tags.tags[tag];
                });
            }
        });
        result[key] = section;
    });
    return result;
}

IpoReadyAssessment.fetchTasks = fetchAssessmentData().then(deserialized);

export default IpoReadyAssessment;
