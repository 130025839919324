import React from "react";
import './Header.scss';
import orrickLogoSrc from "../media/orrick-logo-wordmark.svg";

//import {ToolBox} from "@ltse/hi-kit";

class Header extends React.Component {
    get isPresentingLtScorecard() {
        return this.props.app.appName === "Long Term Scorecard";
    }

    static get partnerView() {
        const orrickDescription =
            "Full-service law firm serving the legal needs of high growth companies through their complete lifecycle.";
        return (
            <div className="content-partner-brand">
                
                <a
                    href="https://www.orrick.com/"
                    target="_blank"
                    rel="noopener"
                >
                    <img
                        className="partner-logo"
                        src={orrickLogoSrc}
                        title={orrickDescription}
                        alt={orrickDescription}
                    />
                </a>
            </div>
        );
    }

    render() {
        return (
            <header key="header">
                <div key="tool-drawer" className="tool-drawer">
                  
                </div>
                <div className="row1">
                    <div className="content">
                        <div className="tool-brand">
                            <h1>{this.props.app.appName}</h1>
                        </div>
                        {!this.isPresentingLtScorecard && Header.partnerView}
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
