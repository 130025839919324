import React, {Component} from 'react';
import '../IpoReady/IpoReady.scss';

import {
    Button,
    Collapse,
    FocusStyleManager,
    Icon,
    Intent,
    Position,
    ProgressBar,
    Radio,
    Spinner,
    Switch,
    Tooltip,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import Status from "../Status/Status";
import InlineHelp from "../InlineHelp/InlineHelp";
import Tags from "../Tags/Tags";
import Feedback from "../Feedback/Feedback";
import Export from "../Export/Export";

import {config} from "../config";
import Assessment from "../data/SeriesAReadyAssessment";
import {statusToaster} from "../toaster";

FocusStyleManager.onlyShowFocusOnTabs();

const MAX_SCORE_TARGET = 7;

class SeriesA extends Component {

    static progressBarIntentForScoreAndTarget(score, target) {
        return (target - score) > 1 ?
            Intent.DANGER :
            score >= target ? Intent.SUCCESS : Intent.WARNING;
    }

    static deserializedAssessmentInputFrom(data) {
        if (Assessment.stages) {
            data.stage = Assessment.stages[data.stage];
        }
        return data;
    }

    static get importSuccessToast() {
        return {
            message: "Progress imported.",
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            timeout: 2000,
        };
    }

    taskListItemContentFor(task) {
        const debugView = this.props.isDebug ? (
            <div className="debug">
                {(task.tags && task.tags.length) ? <Tags tags={task.tags} /> : null}
                {task.stage ? <span>Stage year: {task.stage.year}</span> : null}
                <span>Weight: {task.weight}</span>
            </div>
        ) : null;
        return (
            <div className="task-list-item-content">
                {task.label}
                {task.helpContent && <InlineHelp>
                    <div dangerouslySetInnerHTML={{__html: task.helpContent}} />
                </InlineHelp>}
                {debugView}
            </div>
        );
    }

    scoreFor(sectionTaskLists) {
        const totalScore = sectionTaskLists.reduce((sum, sectionTaskList) =>
            sum + sectionTaskList.reduce((_sum, _task) => _sum + _task.weight, 0),
            0,
        );
        const sectionScore = sectionTaskLists.reduce((sum, sectionTaskList) =>
            sum + sectionTaskList.reduce((sum, task) => {
                let result = sum;
                const status = this.state.assessmentInput.tasks[task.id] ?
                    this.state.assessmentInput.tasks[task.id].status : null;
                if (status) {
                    result += task.weight * status;
                }
                return result;
            }, 0),
            0,
        );
        return (sectionScore / totalScore * 10) * (MAX_SCORE_TARGET / 10);
    }

    constructor(props) {
        super(props);

        this.state = {
            isAssessmentFormDirty: false,
            willWarnBeforeExit: false,
            assessmentDidLoad: false,
            general: {
                taskFilterPredicate: undefined,
            },
            assessmentInput: {
                stage: Assessment.defaultStage,
                tasks: {},
                user: {},
            },
            sections: {},
        };
    }

    get filterView() {
        const filters = Object.entries(Tags.tags).map(([tagKey, tag]) => {
            const className =
                `bp3-tag ${tag.type.className} ${tag === this.state.general.taskFilterPredicate ? "" : "bp3-minimal "}`;
            return (
                <button
                    key={tagKey}
                    className={className}
                    onClick={this.handleTagClick.bind(this)}
                    data-custom={tagKey}
                >
                    {tag.content}
                </button>
            );
        });
        const clearButton = (
            <button
                onClick={this.clearFilter.bind(this)}
                className="bp3-button bp3-small"
            >
                Clear
            </button>
        );
        return (
            <div id="filters">
                <div className="input-row">
                    <h5 className="bp3-heading">Filter Tasks</h5>
                    {this.state.general.taskFilterPredicate && clearButton}
                </div>
                <div>{filters}</div>
            </div>
        );
    }

    get egcView() {
        return (
            <div className="input-row">
                <label className="switch-row">
                    <span>Emerging Growth Company (EGC) status</span>
                    <Switch />
                </label>
                <InlineHelp>
                    <div>
                        <h5 className='bp3-heading'>What is it</h5>
                        <p>From Jumpstart Our Business Startups Act</p>
                        <h5 className='bp3-heading'>How to Qualify</h5>
                        <ul>
                            <li>Have not filed S-1 and sold shares publicly</li>
                            <li>Issued more than $1b in non-convertible debt in past
                                3
                                years
                            </li>
                            <li>Not a Large Accelerated Filer (public float >=
                                $700m)
                            </li>
                        </ul>
                    </div>
                </InlineHelp>
            </div>
        );
    }

    get toDoList() {
        const tasks = Object.values(this.tasks)
            .reduce((output, sectionData) =>
                    [
                        ...output,
                        ...sectionData.tasks.filter(task =>
                            !(this.state.assessmentInput.tasks[task.id] &&
                                this.state.assessmentInput.tasks[task.id].status),
                        ),
                    ],
                [],
            )
            .sort((a, b) => {
                if (Assessment.stages) {
                    if (b.stage.year < a.stage.year) return -1;
                    if (b.stage.year > a.stage.year) return 1;
                }
                if (b.weight < a.weight) return -1;
                if (b.weight > a.weight) return 1;
                // Don't sort alphabetically
                // if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
                // if (b.label.toLowerCase() < a.label.toLowerCase()) return 1;
                return 0;
            });
        const topTasks = tasks.slice(0, 3).map(task => {
            return (
                <li key={task.label}>
                    {this.taskListItemContentFor(task)}
                </li>
            );
        });
        return (
            <ol className="to-do-list">
                {topTasks}
            </ol>
        );
    }

    get toDoListSectionView() {
        return (
            <div className="subsection">
                <h5 className="bp3-heading section-heading">
                    <span>3 priorities to work on next</span>
                    <InlineHelp>
                        This list shows the 3 highest impact
                        tasks that have not yet been started.
                    </InlineHelp>
                </h5>
                {this.toDoList}
            </div>
        );
    }

    get stageSelectorView() {
        return (
            <div id="stage-selector" className="subsection">
                <h5 className="bp3-heading section-heading">What is your stage?</h5>
                <div className="bp3-select bp3-fill">
                    <select
                        onChange={this.handleStageChange.bind(this)}
                        value={this.state.assessmentInput.stage.id}
                    >
                        {Object.values(Assessment.stages).map(stage =>
                            <option key={stage.id} value={stage.id}>
                                {stage.label}
                            </option>,
                        )}
                    </select>
                    <p className="stage-description">{this.state.assessmentInput.stage.description}</p>
                </div>
            </div>
        );
    }

    get outputView() {
        return (
            <section id="output">
                {Assessment.stages ? this.stageSelectorView : null}
                <div className="sticky">
                    <div className="subsection">
                        <h5 className="bp3-heading section-heading">
                            <span>Your Score & Recommended Target</span>
                            <InlineHelp>
                                Recommended target for your stage. Being too close to fully ready
                                before
                                initiating the IPO process results in resource waste.
                            </InlineHelp>
                        </h5>
                        {Object.values(Assessment.sections).map(section =>
                            this.renderProgressOutputViewForSectionAndStage(
                                section,
                                this.state.assessmentInput.stage,
                            )
                        )}
                    </div>
                    {this.toDoListSectionView}
                    <div className="subsection actions">
                        <div>
                            <Export
                                input={this.state.assessmentInput}
                                handleChange={this.handleExportFormChange.bind(this)}
                                prepareAssessmentForExport={this.expandAll.bind(this)}
                                isDebug={this.props.isDebug}
                                questionLabel={Assessment.questionLabel}
                                newsletterLabel={Assessment.newsletterLabel}
                                slackWebhookUri={config.seriesAReadySlackWebhookUri}
                            />
                            <Tooltip
                                content="Select a local data file saved from this app"
                                position={Position.TOP}
                                className="tooltip-wrapper"
                                targetClassName="tooltip-target"
                            >
                                <label htmlFor="restore-progress-file-input" className="bp3-button">
                                    Restore Progress
                                </label>
                            </Tooltip>
                            <input
                                id="restore-progress-file-input"
                                type="file"
                                onChange={this.handleProgressDataFileSelection.bind(this)}
                            />
                        </div>
                        <div>
                            <Button onClick={this.print.bind(this)}>
                                Print to PDF
                            </Button>
                            <Feedback
                                mailto="mailto:TCGSeriesATool@orrick.com"
                                buttonLabel="Help and Feedback"
                                intro={Assessment.contactFormInstructions}
                                slackWebhookUri={config.seriesAReadySlackWebhookUri}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    get assessmentView() {
        return (
            <div className="content">
                <section id="input" className={this.state.isAssessmentFormDirty ? "" : "is-dirty"}>
                    {/*<section id="general" className="section">*/}
                    {/*/!*{this.filterView}*!/*/}
                    {/*/!*{this.egcView}*!/*/}
                    {/*</section>*/}
                    {Object.entries(Assessment.sections).map(([sectionId, section]) => {
                        return (
                            <section
                                id={sectionId}
                                key={sectionId}
                                className="section bp3-card bp3-elevation-2"
                            >
                                <div className="section-heading-container">
                                    <h2 className="bp3-heading section-heading">
                                        <Icon
                                            icon={section.icon}
                                            className="section-heading-icon"
                                            iconSize={24}
                                        />
                                        <a className="same-page-link" href={`#${sectionId}`}>
                                            {section.label}
                                        </a>
                                    </h2>
                                    <p className="subheading">{section.subhead}</p>
                                </div>
                                {section.taskListIds.map(taskListId =>
                                    this.renderSubsectionWith(this.tasks[taskListId])
                                )}
                            </section>
                        );
                    })}
                </section>
                {this.outputView}
            </div>
        );
    }

    handleTagClick(event) {
        this.setState({
            general: {
                ...this.state.general,
                taskFilterPredicate: Tags.tags[event.target.dataset.custom],
            },
        });
    }

    handleStatusUpdateForTaskInSection(task, section) {
        return ({currentTarget}) => {
            const status = parseFloat(currentTarget.value);
            const oldTaskIndex = section.tasks.indexOf(task);
            const newTasks = section.tasks.slice();
            newTasks[oldTaskIndex].status = status;
            if (window.gtag) {
                window.gtag("event", "change_status", {
                    "event_category": "task",
                    "event_label": task.label,
                    "value": task.status,
                });
            }
            this.setState({
                assessmentInput: {
                    ...this.state.assessmentInput,
                    tasks: {
                        ...this.state.assessmentInput.tasks,
                        [task.id]: {status},
                    },
                },
                isAssessmentFormDirty: !this.state.isAssessmentFormDirty ?
                    true : this.state.isAssessmentFormDirty,
            });
        };
    }

    handleStageChange({target}) {
        if (window.gtag) {
            window.gtag("event", "select_stage", {
                "event_category": "assessment",
                "value": target.value,
            });
        }
        this.setState({
            assessmentInput: {
                ...this.state.assessmentInput,
                stage: Assessment.stages[target.value],
            },
        });
    }

    handleProgressDataFileSelection(event) {
        if (window.gtag) {
            window.gtag("event", "restore_progress", {
                "event_category": "app",
            });
        }
        const fileReader = new FileReader();
        fileReader.onload = event => {
            const result = JSON.parse(event.target.result);
            this.setState(
                {
                    assessmentInput: SeriesA.deserializedAssessmentInputFrom(result),
                    isAssessmentFormDirty: true,
                },
                function () {
                    statusToaster.show(SeriesA.importSuccessToast);
                });
        };
        fileReader.readAsText(event.target.files.item(0));
    }

    handleExportFormChange(event) {
        const key = event.target.dataset.fieldKey;
        const valueKey = event.target.type === "checkbox" ? "checked" : "value";
        this.setState({
            assessmentInput: {
                ...this.state.assessmentInput,
                user: {
                    ...this.state.assessmentInput.user,
                    [key]: event.target[valueKey],
                },
            }
        });
    }

    handleExpand(event) {
        const sectionId = event.currentTarget.dataset.sectionId;
        if (window.gtag) {
            window.gtag("event", "expand_section", {
                "event_category": "app",
                "event_label": sectionId,
            });
        }
        this.setState({
            sections: {
                ...this.state.sections,
                [sectionId]: {
                    isOpen: !this.state.sections[sectionId].isOpen,
                },
            },
        });
    }

    expandAll(callback) {
        const sections = {...this.state.sections};
        Object.values(sections).forEach(section => {
            section.isOpen = true;
        });
        this.setState({sections}, function () {
            if (callback) setTimeout(callback, 500);
        });
    }

    clearFilter() {
        this.setState({
            general: {
                ...this.state.general,
                taskFilterPredicate: undefined,
            },
        });
    }

    print() {
        if (window.gtag) {
            window.gtag("event", "print", {
                "event_category": "app",
            });
        }
        this.expandAll(window.print);
    }

    renderTaskListFor(section) {
        let filteredTasks;
        if (this.state.general.taskFilterPredicate) {
            filteredTasks = section.tasks.filter(
                task => task.tags && task.tags.includes(this.state.general.taskFilterPredicate),
            );
        }
        // TODO: add back all tags
        const taskListItems = (filteredTasks || section.tasks).map((task) => {
            let filteredTags;
            if (task.tags && task.tags.length) {
                filteredTags = task.tags
                    .filter(tag => [Tags.tags.LTSE, Tags.tags.ORRICK].includes(tag));
            }
            const status = this.state.assessmentInput.tasks[task.id] ?
                this.state.assessmentInput.tasks[task.id].status : null;
            return <div key={task.label} className="input-row">
                <Status
                    handleChange={this.handleStatusUpdateForTaskInSection(task, section)}
                    status={status}
                />
                {(filteredTags && filteredTags.length && !this.props.isDebug) ?
                    <Tags tags={filteredTags} /> : null}
                {this.taskListItemContentFor(task)}
            </div>;
        });
        return (
            <div className="task-list">
                {taskListItems}
                {/* Need a fixed height spacer instead of margin / padding because Blueprint
                    Collapse animation doesn't take margin / padding into consideration */}
                <div className="spacer" />
            </div>
        );
    }

    renderSubsectionWith(sectionData) {
        return (
            <div className="subsection" key={sectionData.id}>
                <h4 id={sectionData.id} className="bp3-heading section-heading">
                    <Button
                        onClick={this.handleExpand.bind(this)}
                        className="bp3-button bp3-small"
                        icon={
                            this.state.sections[sectionData.id].isOpen ?
                                IconNames.CARET_DOWN : IconNames.CARET_RIGHT
                        }
                        data-section-id={sectionData.id}
                    />
                    <a className="same-page-link" href={`#${sectionData.id}`}>
                        {sectionData.label}
                    </a>
                    {
                        sectionData.description &&
                        <InlineHelp>
                            <div dangerouslySetInnerHTML={{__html: sectionData.description}} />
                        </InlineHelp>
                    }
                </h4>
                <Collapse isOpen={this.state.sections[sectionData.id].isOpen}>
                    {this.renderTaskListFor(sectionData)}
                </Collapse>
            </div>
        );
    }

    renderProgressOutputViewForSectionAndStage(section, stage) {
        const taskLists = section.taskListIds.map(taskListId => this.tasks[taskListId].tasks);
        const score = this.scoreFor(taskLists);
        const target = stage ? section.recommendedTargets.get(stage) : MAX_SCORE_TARGET;
        return (
            <div className="progress-output" key={section.label}>
                <strong>{section.label}</strong>
                <div className="score">
                    <ProgressBar
                        className="bp3-no-animation"
                        intent={SeriesA.progressBarIntentForScoreAndTarget(score, target)}
                        value={score / MAX_SCORE_TARGET}
                    />
                    <span className="bp3-monospace-text bp3-text-small">
                        {Math.round(score)} / {MAX_SCORE_TARGET}
                    </span>
                </div>
                <div className="score">
                    <ProgressBar
                        className="bp3-no-animation"
                        intent={Intent.NONE}
                        value={target / MAX_SCORE_TARGET}
                    />
                    <span className="bp3-monospace-text bp3-text-small">
                        {target} / {MAX_SCORE_TARGET}
                    </span>
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.title = Assessment.pageTitle;
        document.querySelector('meta[name=description]').content =
            Assessment.pageDescription;

        Assessment.fetchTasks.then(tasks => {
            this.tasks = tasks;
            const state = {
                assessmentDidLoad: true,
                sections: {},
            };
            Object.keys(tasks).forEach((sectionId, i) => {
                state.sections[sectionId] = {
                    isOpen: this.props.isDebug ? true : (i === 0),
                };
            });
            this.setState(state);
        });
        if (this.props.isDebug) {
            fetch("../series-a-ready-assessment-sample-response.json")
                .then(function (response) {
                    return response.json();
                })
                .then(data => {
                    this.setState({assessmentInput: SeriesA.deserializedAssessmentInputFrom(data)});
                });
        }
    }

    componentDidUpdate() {
        if (this.state.isAssessmentFormDirty &&
            !this.state.willWarnBeforeExit &&
            !this.props.isDebug) {
            window.addEventListener("beforeunload", function (event) {
                event.returnValue =
                    "The assessment is not saved. Use browser's print to PDF feature to save progress.";
            });
            this.setState({willWarnBeforeExit: true});
        }
    }

    render() {
        return (
            <div id="ipo-ready" className="app-body">
                <section className="intro">
                    <p>
                        {Assessment.headerDescription}
                    </p>
                    <div id="status-legend" className="status">
                        <Radio className="bp3-intent-danger" checked readOnly inline label="To start" />
                        <Radio className="bp3-intent-warning" checked readOnly inline label="In progress" />
                        <Radio className="bp3-intent-success" checked readOnly inline label="Finished" />
                    </div>
                </section>
                {this.state.assessmentDidLoad ? this.assessmentView : <Spinner size={50} />}
            </div>
        );
    }
}

SeriesA.appName = "Series A Ready";

export default SeriesA;
