export const configs = {
    // TODO: use different Slack webhooks for prod and dev
    DEVELOPMENT: {
        ipoReadySlackWebhookUri: "https://hooks.slack.com/services/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",
        seriesAReadySlackWebhookUri: "https://hooks.slack.com/services/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",
    },
    PRODUCTION: {
        ipoReadySlackWebhookUri: "https://hooks.slack.com/services/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",
        seriesAReadySlackWebhookUri: "https://hooks.slack.com/services/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",
    }
};

export const config = process.env.NODE_ENV === 'development' ?
    configs.DEVELOPMENT : configs.PRODUCTION;
