import React from "react";
import {Dialog} from "@blueprintjs/core";

import LTSELogoSrc from "../media/ltse-logo.svg";
import orrickLogoSrc from "../media/orrick-logo-wordmark.svg";

import './Footer.scss';

class Footer extends React.Component {
    static get orrickPromo() {
        return (
            <p>
                Content developed with our friends at <a
                href="https://www.orrick.com/"
                target="_blank"
                rel="noopener"
            >Orrick</a>, a full-service law firm serving the legal needs of high growth companies through their complete lifecycle.
            </p>
        );
    }

    get termsDialogContent() {
        return [
            <div key="body" className="bp3-dialog-body">
                <p>By using X-Ready products, including IPO Ready and Series A Ready, you agree to the following:</p>
                <strong>No Attorney-Client Relationship</strong>
                <p>X-Ready products, including IPO Ready and Series A Ready (“XREADY”) has been prepared by Orrick, Herrington & Sutcliffe
                    LLP for general informational purposes only
                    and does not constitute advertising, a solicitation or rendering of
                    legal or business advice, either generally or in connection with any
                    specific issue or case. Neither the availability, operation,
                    transmission, receipt nor use of XREADY is intended to create, or
                    constitutes formation of, an attorney-client relationship or any other
                    special relationship or privilege. These materials are intended for
                    general informational and educational purposes only. You should not rely
                    upon XREADY for any purpose without seeking legal advice from licensed
                    attorneys in the relevant state(s).</p>
                <strong>Compliance with Laws; Indemnity</strong>
                <p>You agree to use XREADY in compliance with all applicable laws, including
                    applicable securities laws, and you agree to indemnify and hold Orrick,
                    Herrington & Sutcliffe LLP , its affiliates and its and their respective past, present and future partners, members, directors, officers, employees, agents, representatives, heirs, immediate family members, devisees, trustees, successors, assigns, assignors, accountants, attorneys, and associated or related individuals or entities, 
                     harmless from and against any and all claims,
                    damages, losses or obligations arising from your failure to comply.</p>
                <strong>Disclaimer and Waiver of Liability</strong>
                <p>XREADY IS PROVIDED AS-IS WITH NO REPRESENTATIONS OR WARRANTIES, EITHER
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
                    YOU ASSUME COMPLETE RESPONSIBILITY AND RISK FOR USE OF XREADY. Some
                    jurisdictions do not allow the exclusion of implied warranties, so the
                    above exclusion may not apply to you.</p>
                <p>Orrick, Herrington & Sutcliffe
                    LLP and its affiliates expressly disclaim all liability, loss or risk
                    incurred as a direct or indirect consequence of the use of XREADY. By
                    using XREADY, you waive any rights or (“Claims”) you may have against
                    Orrick, Herrington & Sutcliffe LLP and
                    their affiliates in connection therewith , except as may be required by applicable law. The information contained in
                    XREADY is provided only as general information and may not reflect the
                    most current market and legal developments and may not address all
                    relevant business or legal issues; accordingly, information in XREADY is
                    not promised or guaranteed to be correct or complete. Further, Orrick, Herrington & Sutcliffe LLP
                    and its affiliates do not necessarily endorse, and are not responsible
                    for, any third-party content that may be accessed through XREADY.</p>
                    <p>You hereby acknowledge and agree that you are familiar with Section 1542 of the Civil Code of the State of California (“Section 1542”), which provides as follows:
                    A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.
                    </p>
                    <p>
                    You hereby waive and relinquish any rights and benefits that you may have under Section 1542 or any similar statute or common law principle of any jurisdiction. You acknowledge that you may hereafter discover facts in addition to or different from those that you now know or believe to be true with respect to the subject matter of this release, but it your intention to fully and finally waive and release any and all Claims that do or may now exist, heretofore have or may have existed, or hereafter may exist, in each case, with respect to XREADY and your use thereof.  In furtherance of this intention, the waiver and release contained herein shall be and remain in effect as a full and complete release notwithstanding the discovery or existence of any such additional or different facts.
                    </p>
                    <strong>Privacy Notice and Other Terms and Conditions</strong>
                    <p>
                    For more information on how Orrick collects, uses, and discloses personal information, please see our Privacy Notice, available at https://www.orrick.com/en/Privacy-Notice and Cookie Notice, available at https://www.orrick.com/en/Cookie-Notice. 
                    </p>
                    <p>
                    By using XREADY, you further agree to the terms, conditions, notices and disclaimers set forth at https://www.orrick.com/en/Legal-Notices, https://www.orrick.com/en/Privacy-Notice, https://www.orrick.com/en/Cookie-Notice, https://www.orrick.com/en/Modern-Slavery-Statement and https://www.orrick.com/en/Attorney-Advertising
                    </p>
            </div>,
            <div key="footer" className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <button
                        className="bp3-button bp3-intent-primary"
                        onClick={this.toggleTermsDialog.bind(this)}
                    >
                        I accept
                    </button>
                </div>
            </div>,
        ];
    }

    constructor(props) {
        super(props);
        this.state = {isTermsDialogOpen: false};
    }

    get isPresentingLTScorecard() {
        return this.props.app.appName === "Long Term Scorecard";
    }

    toggleTermsDialog() {
        this.setState({isTermsDialogOpen: !this.state.isTermsDialogOpen});
    }

    render() {
        return (
            <footer>
                <div className="logo-container">
                    
                    {!this.isPresentingLTScorecard &&
                        <a href="https://www.orrick.com/" target="_blank" rel="noopener">
                            <img className="logo" src={orrickLogoSrc} alt="Orrick Logo" />
                        </a>
                    }
                </div>
                <div className="content">
                    <p>
                    © 2024 Orrick, Herrington & Sutcliffe, LLP. All rights reserved. &nbsp;
                        {!this.isPresentingLTScorecard && <span className="link-button" role="button" onClick={this.toggleTermsDialog.bind(this)}>Terms of Use</span>}
                    </p>
                   
                </div>
                <Dialog
                    className="terms-dialog"
                    isOpen={this.state.isTermsDialogOpen}
                    onClose={this.toggleTermsDialog.bind(this)}
                    title="Terms of Use"
                    isCloseButtonShown={false}
                >
                    {this.termsDialogContent}
                </Dialog>
            </footer>
        );
    }
}

export default Footer;
